.App {
  align-items: center;
  background-image: url("https://cutewallpaper.org/21/music-background-images/Notes-And-Butterflies-Music-Background-Assets-For-All.jpg");
  height: 100vh;
  background-size: contain;
}

a{
  color: black;
  text-decoration: none; /* no underline */
}

